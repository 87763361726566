import React, { useState } from 'react'
import Small from '../../images/hS.png'
import Medium from '../../images/hS.png'
import Large from '../../images/hM.png'
import LinkIn from '../../images/ii0.svg'
import Ms from '../../images/ii2.svg'
import Mails from '../../images/mailS.png'
import LinkIns from '../../images/linkS.png'
import Mail from '../../images/ii4.svg'
import Face from '../../images/ii1.svg'
import Faces from '../../images/smile.svg'
import Project from './Project'
import Review from './Review'
import Contactus from './Contactus'
function Main() {
 const [fixed, setFixed] = useState(false)

 function checkScroll  () {
      if(window.scrollY >= 0){
        setFixed(true)
      }else{
        setFixed(false)
      }
 }

 window.addEventListener("scroll", checkScroll)

  return (
    <div className= ' sm:mx-5 pt-5 mx-10 '>
        <div className='sm:fixed sm:px-5 sm:top-[6%]  sm:mt-12 sm:left-0 '>
        <div className= ' sm:flex sm:w-1/2' >
            <div >
            <div className=' flex md:block pt-20 sm:pt-0 justify-between sm:justify-center '>
            <div >
              <img src={Large} width={278} height={278} className=' hidden mx-auto lg:block' alt='Aimua'/>
                <img src={Medium} className=' hidden  justify-center mx-auto sm:flex lg:hidden' alt='Aimua'/>
                <img src={Small} className=' block  sm:hidden lg:hidden' alt='Aimua'/>
            </div>
            <div className=' flex flex-col sm:hidden justify-center gap-4 ml-6'>
            <div className=' flex gap-3'>
            <div>
            <a href='https://www.linkedin.com/in/aimuan' target='_blank' rel='noreferrer'><img src={LinkIns} width={28} height={28} alt='linkIn'/></a> 
            </div>
            <div>
            <a href='https://adplist.org/mentors/ai' target='_blank' rel='noreferrer'><img src={Faces}  width={28} height={28} className='' alt='Face'/></a>
            </div>
            <div>
            <a href='https://medium.com/@aithelight/beyond-buzzwords-why-practicing-ux-is-key-to-mastering-user-centered-design-%EF%B8%8F-ed3050217b33?source=friends_link&sk=8ac05769dae2514583b111dca3c606af' target='_blank' rel='noreferrer'><img src={Ms} width={28} height={28} className='' alt='Face'/></a>
            </div>
            <div>
            <a href='#contact'> <img src={Mails} width={28} height={28} alt='mail'/> </a>
           
            </div>
            </div>
            
              
            </div>
            </div>
            
               <h1 className=' text-start sm:text-center text-primary-color font-[500] text-[29px] lg:text-[32px] pt-3'>Hi, I am Aimua 🙏🏽</h1>
               <p className=' text-start sm:text-center text-sub-text py-[24px] text-[16px] lg:text-[20px] font-[400] mx-auto md:w-1/2'>I blend design, product, and business thinking to shape meaningful products that people actually want to use.</p>
               <div className=' hidden wow animate__animated animate__zoomInUp gap-4  sm:flex justify-center'>
                  <a href='https://www.linkedin.com/in/aimuan' target='_blank' rel='noreferrer'> <img src={LinkIn} alt='linkIn'/></a> 
                    <a href='https://adplist.org/mentors/ai' target='_blank' rel='noreferrer'><img src={Face} className=' ' alt='Face'/></a>
                    <a href='https://medium.com/@aithelight/beyond-buzzwords-why-practicing-ux-is-key-to-mastering-user-centered-design-%EF%B8%8F-ed3050217b33?source=friends_link&sk=8ac05769dae2514583b111dca3c606af' target='_blank' rel='noreferrer'><img src={Ms}  className='' alt='Face'/></a>
                   <a href='#contact'><img src={Mail} alt='mail'/></a> 
               </div>
               
            </div>
            </div>
        </div>
        <div className=' sm:grid sm:grid-cols-2  sm:gap-4 sm:pt-10 md:mx-5 sm:overflow-y-auto'>
        <div className= 'hidden sm:block'>

        </div>
          <div className= 'sm:mr-10 sm:z-30' >
          <Project/>
            
            <Review/>
            <Contactus/>
          </div>
         
        </div>
    </div>
  )
}

export default Main